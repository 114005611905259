<template>
  <div class="Accordion">
    <!-- Header -->
    <div
      class="Header"
      @click="onToggleBody">
      <div class="LeftSide">
        {{ title }}
      </div>
      <div
        v-if="bodyComponent"
        class="RightSide">
        <div
          :class="{ 'Open': isOpen }"
          class="Arrow">
          <AngleRightIcon />
        </div>
      </div>

    </div>

    <!-- Body -->
    <div
      v-if="bodyComponent"
      ref="body"
      :class="bodyClasses"
      class="Body"
      :style="{
        height: bodyHeight !== null ? bodyHeight + 'px' : '',
      }">
      <div class="Inner">
        <component
          :is="bodyComponent"
          :added-category-types="addedCategoryTypes"
          v-bind="{ ...bodyComponentProps }" />
      </div>
    </div>
  </div>
</template>

<script>
import AngleRightIcon from '@/assets/svg/angle-right.svg?inline'

export default {
  name: 'Accordion',
  props: {
    bodyComponent: {
      type: Object,
      required: false,
      default: () => null,
    },
    bodyComponentProps: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    addedCategoryTypes: {
      type: Array,
      required: false,
      default: () => [],
    },
    bodyProps: {
      type: Object,
      required: false,
      default: null,
    },
    isOpenOnLoad: {
      type: Boolean,
      required: false,
      default: true,
    },
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: '',
    },
    padding: {
      type: String,
      required: false,
      default: 'standard', // 'standard' | 'none'
    },
  },
  data() {
    return {
      bodyHeight: null,
      isOpen: this.isOpenOnLoad,
    }
  },
  computed: {
    bodyClasses() {
      return {
        Open: this.isOpen,
        PaddingStandard: this.padding === 'standard',
        PaddingNone: this.padding === 'none',
      }
    },
  },
  methods: {
    onToggleBody() {
      if (this.isOpen) {
        this.closeBody()
        return
      }

      this.openBody()
    },
    closeBody() {
      if (!this.$refs.body) {
        return
      }

      // Get height
      const { height } = this.$refs.body.getBoundingClientRect()

      // Set start height
      this.bodyHeight = height

      // Set end height
      requestAnimationFrame(() => {
        this.bodyHeight = 0
      })

      this.isOpen = !this.isOpen
    },
    openBody() {
      // Get height
      this.bodyHeight = null
      requestAnimationFrame(() => {
        const { height } = this.$refs.body.getBoundingClientRect()

        // Set start height
        this.bodyHeight = 0

        requestAnimationFrame(() => {
          // Set end height
          this.bodyHeight = height

          // But do not hardcode the height forever, content inside might change.
          setTimeout(() => {
            this.bodyHeight = null
          }, 300)
        })
      })

      this.isOpen = !this.isOpen
    },
  },
  components: {
    AngleRightIcon,
  },
  mounted() {
    // Set height on load
    if (!this.isOpen) {
      this.bodyHeight = 0
    }
  },

}
</script>

<style lang="stylus" scoped>
  .Accordion
    display block
    margin-bottom 30px

  .Header
    display flex
    align-items center
    justify-content space-between
    padding-left 15px
    background-color $color_grey_lightest
    border 1px solid $color_grey_lighter
    cursor pointer
    .LeftSide
      font-size 1.25rem
      height 44px
      flex-center-children()
    .Arrow
      box(44px)
      padding 13px
      svg
        transform rotate(90deg)
      &.Open
        svg
          transform rotate(-90deg)

  .Body
    background-color #fff
    overflow hidden
    transition all .2s ease
    opacity 0
    border 1px solid $color_grey_lighter
    border-top none
    &.Open
      opacity 1
    &.PaddingStandard
      .Inner
        padding 15px
    &.PaddingNone
      .Inner
        padding 0

</style>
