<template>
  <div
    class="InputField"
    :class="rootClasses">
    <label
      v-if="label"
      :for="name">{{ label }}</label>
    <div
      :class="{ 'HasIcon': icon }"
      class="InputWrap">
      <input
        ref="inputField"
        :id="name"
        :type="type"
        :value="mutatedValue"
        :placeholder="mutatedPlaceholder"
        :disabled="disabled"
        :name="name"
        :autocomplete="disableAutoSuggest ? 'off' : 'on'"
        :required="isRequired"
        @input='onInput'
        @focus="onFocus"
        @blur="onBlur"
        @keyup.enter="onEnter" />
      <div
        class="InputIcon"
        v-if="icon">
        <component :is="iconToUse" />
      </div>
      <span
        v-if="unit"
        class="Unit">{{ unit }}</span>
    </div>
    <span
      v-if="states.showError"
      class="ErrorText">{{ mixWB('THIS_FIELD_IS_MISSING') }}</span>
  </div>
</template>

<script>
import UserIcon from '@/assets/svg/user.svg?inline'
import PasswordIcon from '@/assets/svg/password.svg?inline'
import EventBus from '@/EventBus'

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    unit: {
      type: String,
      required: false,
      default: '',
    },
    backgroundColor: {
      type: String,
      required: false,
      default: 'grey', // 'white' | 'grey'
    },
    focusTrigger: {
      type: Number,
      required: false,
      default: 0,
    },
    autoFocus: {
      type: Boolean,
      required: false,
      default: false,
    },
    disableAutoSuggest: {
      type: Boolean,
      required: false,
      default: false,
    },
    formGroupName: {
      type: String,
      required: false,
      default: '',
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
    },
    trimInput: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      previousValue: '',
      states: {
        isDirty: false,
        isEmpty: false,
        showError: false,
      },
    }
  },
  computed: {
    rootClasses() {
      return {
        BackgroundColorGrey: this.backgroundColor === 'grey',
        BackgroundColorWhite: this.backgroundColor === 'white',
        IsDisabled: this.disabled,
      }
    },
    mutatedValue() {
      if (this.type === 'number') {
        if (!this.value && this.previousValue) {
          return this.previousValue
        }

        if (!this.value && !this.states.isDirty) {
          return ''
        }

        if (this.states.isDirty && this.states.isEmpty) {
          return ''
        }
      }

      return this.value
    },
    mutatedPlaceholder() {
      if (this.type === 'number') {
        return this.placeholder || '-'
      }

      return this.placeholder
    },
    iconToUse() {
      if (this.icon === 'user') {
        return UserIcon
      }
      if (this.icon === 'password') {
        return PasswordIcon
      }
      return PasswordIcon
    },
  },
  watch: {
    focusTrigger() {
      this.$refs.inputField.focus()
    },
  },
  methods: {
    onInput(e) {
      this.states.showError = false

      let { value } = e.target

      this.states.isDirty = true
      this.states.isEmpty = value === ''

      // Trim leading and trailing spaces
      if (this.trimInput) {
        value = value.replace(/^\s+|\s+$/g, '')
      }

      if (this.type === 'number') {
        this.previousValue = value
        const sameNumber = this.value === Number(value)

        if (sameNumber) {
          return
        }

        this.$emit('on-update', { name: this.name, value: Number(value) || 0 })
        return
      }

      this.$emit('on-update', { name: this.name, value })
    },
    onFocus(e) {
      this.$emit('focus', { name: this.name, value: e.target.value })
    },
    onBlur(e) {
      this.$emit('blur', { name: this.name, value: e.target.value })
    },
    onEnter(e) {
      e.target.blur()
      this.$emit('enter', { name: this.name, value: e.target.value })
    },
    onValidateField(formGroupName) {
      if (!this.isRequired || !this.formGroupName || this.formGroupName !== formGroupName) {
        return
      }

      if (this.value) {
        this.states.showError = false
        return
      }

      this.states.showError = true
    },
  },
  components: {
    UserIcon,
    PasswordIcon,
  },
  created() {
    this.states.isEmpty = this.value === ''
    EventBus.$on('validate-field', this.onValidateField)
  },
  mounted() {
    if (this.autoFocus) {
      requestAnimationFrame(() => {
        this.$refs.inputField.focus()
      })
    }
  },
  destroyed() {
    EventBus.$off('validate-field', this.onValidateField)
  },
}
</script>

<style lang="stylus" scoped>
  .InputField
    margin-bottom 10px
    &.BackgroundColorGrey
      input
        background-color $color_grey_lightest
    &.BackgroundColorWhite
      input
        background-color #fff
    &.IsDisabled
      input
        color $color_grey
      &.BackgroundColorGrey
        input
          background-color $color_grey_lighter
      &.BackgroundColorWhite
        input
          background-color $color_grey_lightest
    label
      padding-bottom 5px
      display block

  .InputWrap
    position relative
    input
      height 40px
      border 1px solid $color_grey_lighter
      padding-left 10px
      padding-right 10px
    &.HasIcon
      input
        padding-left 40px
    .Unit
      position absolute
      top 0
      right 10px
      height 100%
      flex-center-children()
      color $color_grey
      text-transform lowercase
      pointer-events none

  .InputIcon
    position absolute
    top 0
    left 0
    width 40px
    height 40px
    padding 10px
    svg
      fill $color_grey

  .ErrorText
    display block
    color $color_error
    margin-top 5px

</style>
